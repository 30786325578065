var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-torrent"},[_c('el-card',{staticClass:"box-card"},[_c('el-form',{ref:"form",attrs:{"label-width":"100px"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"搜索关键词"}},[_c('el-input',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.extSearch)?[_c('el-form-item',{attrs:{"label":"启用站点"}},[_c('el-checkbox-group',{model:{value:(_vm.searchSite),callback:function ($$v) {_vm.searchSite=$$v},expression:"searchSite"}},_vm._l((_vm.enabledSites),function(enabledSite){return _c('el-checkbox',{key:enabledSite,attrs:{"label":enabledSite}},[_vm._v(" "+_vm._s(_vm.getSiteMetaData(enabledSite).name)+" ")])}),1)],1)]:_vm._e(),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","disabled":_vm.searchSite.length === 0 || _vm.disableSearch},on:{"click":_vm.startSearch}},[_vm._v("搜索 ")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){_vm.extSearch = !_vm.extSearch}}},[_vm._v(" "+_vm._s(_vm.extSearch ? '隐藏' : '显示')+" 更多选项 ")])],1)],2)],1),_c('el-divider'),_c('el-table',{attrs:{"data":_vm.searchData,"stripe":"","size":"small","default-sort":{prop: 'time', order: 'descending'}}},[_c('el-table-column',{staticClass:"captionText",attrs:{"label":"站点","align":"center","width":"80px","sortable":"","prop":"source.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"href":row.source.url}},[_vm._v(_vm._s(row.source.name))]),_c('br'),_c('span',{staticStyle:{"white-space":"nowrap"},attrs:{"title":row.category}},[_vm._v(_vm._s(row.category || 'Other'))])]}}])}),_c('el-table-column',{attrs:{"label":"标题"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{staticStyle:{"white-space":"nowrap"},attrs:{"href":row.url,"target":"_blank","title":row.title}},[_vm._v(_vm._s(row.title))]),_c('br'),_c('div',{staticClass:"sub-title captionText"},[(row.tags && row.tags.length > 0)?_c('span',{staticClass:"tags"},_vm._l((row.tags),function(tag){return _c('span',{key:tag.name,style:({'background-color': tag.color})},[_vm._v(_vm._s(tag.name))])}),0):_vm._e(),_c('span',{staticStyle:{"white-space":"nowrap"},attrs:{"text":row.subTitle}},[_vm._v(_vm._s(row.subTitle || ''))])])]}}])}),_c('el-table-column',{attrs:{"label":"大小","width":"100px","align":"center","prop":"size","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatSizeString(row.size)))]}}])}),_c('el-table-column',{attrs:{"label":"上传","prop":"seeders","align":"center","width":"70px","sortable":""}}),_c('el-table-column',{attrs:{"label":"下载","prop":"leechers","align":"center","width":"70px","sortable":""}}),_c('el-table-column',{attrs:{"label":"完成","prop":"completed","align":"center","width":"70px","sortable":""}}),_c('el-table-column',{attrs:{"label":"发布于(≈)","prop":"time","align":"center","width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatDatetime(row.time)))]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":row.link,"download":""}},[_c('i',{staticClass:"el-icon-download"})])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }